export const LANGUAGE_EXPANDED_MAP = {
    en: 'English',            // English
    de: 'Deutsch',            // German
    es: 'Español',            // Spanish
    fr: 'Français',           // French
    pt: 'Português',          // Portugues
    ja: '日本語',              //Japanese
    ko: '한국어',              // Korean
    cs: 'Čeština',           // Czech
    da: 'Dansk',             // Danish
    nl: 'Nederlands',        // Dutch
    el: 'Ελληνικά',          // Greek
    id: 'Bahasa Indonesia',  // Indonesian
    it: 'Italiano',          // Italian
    ms: 'Bahasa Melayu',     // Malay
    nb: 'Norsk Bokmål',      // Norwegian Bokmål
    pl: 'Polski',            // Polish
    ro: 'Română',            // Romanian
    ru: 'Русский',           // Russian
    sv: 'Svenska',           // Swedish
    th: 'ไทย',               // Thai
    tr: 'Türkçe',            // Turkish
    uk: 'Українська',        // Ukrainian
    vi: 'Tiếng Việt',        // Vietnamese
    fil: 'Filipino (Tagalog)', // Filipino
    fi: 'Suomi',             // Finnish
    'zh-cn': '简体中文',         // Chinese simplified
    "zh-tw": "繁體中文",         // "Chinese Traditional",
};

export const CATEGORY_LIST = {
    all: "all",
    fashion: "fashion",
    memes: "memes",
    freetoremix: "freetoremix",
    nature: "nature",
    landscape: "landscape",
    travel: "travel",
    lifestyle: "lifestyle",
    entertainment: "entertainment",
    buildings: "buildings",
    flowers: "flowers",
    food: "food",
    animals: "animals",
    sports: "sports"
}

export const SOCIAL_APP = {
    Twitter: "Twitter",
    Whatsapp: "Whatsapp",
    Facebook: "Facebook"
}

export const SEARCH_PATH_URL = "search";

export const HASHTAGS: Array<string> = ["PSX", "Photoshop", "PhotoshopExpress", "ExpressYourself"];

export const HTTP_SUCCESS_OK = 200;

export const ALL_CATEGORIES = "all";

export const featureItems = [
    { name: 'looks', icon: 'looks.svg' },
    { name: 'split_tone', icon: 'blur.svg' },
    { name: 'Blur', icon: 'blur.svg' },
    { name: 'Vignette', icon: 'vignette.svg' },
    { name: 'basic_heal', icon: 'basic_heal.svg' },
    { name: 'Crop', icon: 'crop.svg' },
    { name: 'replace_eyes', icon: 'eyes.svg' },
    { name: 'Stickers', icon: 'stickers.svg' },
    { name: 'skin_smoothing', icon: 'smooth.svg' },
    { name: 'patch_heal', icon: 'patch_heal.svg' },
    { name: 'heal_tilt_face', icon: 'tilt_face.svg' },
    { name: 'makeup_lips', icon: 'makeup.svg' },
    { name: 'Twril', icon: 'twirl.svg' },
    { name: 'faceaware_liquify_lips_smile', icon: 'liquify_smile.svg' },
    { name: 'Draw', icon: 'draw.svg' },
    { name: 'Cutout', icon: 'cutout.svg' },
    { name: 'domo_cutout', icon: 'cutout.svg' },
    { name: 'Themes', icon: 'themes.svg' },
    { name: 'Layouts', icon: 'layouts.svg' },
    { name: 'Scrapbook', icon: 'scrapbook.svg' }
]

export const workflowsItems = [
    { name: "photo_editor", icon: "photo_edit.svg" },
    { name: "face_retouch", icon: "face.svg" },
    { name: "combine_photos", icon: "combine.svg" },
    { name: "collage", icon: "collage.svg" },
    { name: "camera", icon: "capture.svg" }
]

