import { Button, Flex, Text } from "@adobe/react-spectrum";
import Download from '@spectrum-icons/workflow/Download';
import Workflows from "./WorkFlows";
import { useTranslations } from "next-intl";
import { useState } from "react";
import QRModal from "../QRModal/Modal";
import { usePathname } from "next/navigation";


export default function FrameTabs() {
    const t = useTranslations('');
    const [modal, setModal] = useState(false);
    const path = usePathname();
    const routes: Array<string> = path.split("/");

    const handleModal = () => {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        } else
            setModal(!modal);
    }

    return <Flex marginBottom={{ L: 50, base: 20 }} justifyContent={'center'} flexGrow={1} direction={'column'} alignItems={'center'} gap={40} id="workflow">
        <Workflows />
        <Button variant="accent" width={'size-1650'} onPress={handleModal}>
            <Flex isHidden={{ M: true }}>
                <Download />
                <Text >{t('homeScreen.download_photoshop_express')}</Text>
            </Flex>
            <Text isHidden={{ base: true, M: false }}>{t('get_the_app')}</Text>
        </Button>
        <QRModal open={modal} setOpen={handleModal} lang={routes[1]} />
    </Flex>
}