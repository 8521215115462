

export function generateDescrption(metaTags) {
    let desc = "Explore online Photoshop Express on web photo filters by {author}. This image edit has ";
    const pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    for (let index = 0; index < metaTags.length; index++) {
        const element = metaTags[index];
        if (element && !element.includes("_") && element !== "FreeToRemix" && !pattern.test(element) && desc.length + element?.length <= 150) {
            desc += `${capitalizeFirstLetter(element)} `;
        }
    }
    return desc.trim();
}
export function generateTitleAndDescription(data) {
    let description = data?.description;
    let meta = []
    meta = meta.concat(data.tags);
    meta = meta.concat(data.machine_tags);
    meta = meta.concat(data.auto_tags);
    const desc = generateDescrption(Array.from(new Set(meta)));
    if (!description)
        description = desc;
    let title = data?.title;
    //eslint-disable-next-line
    const pattern = /^[0-9A-Fa-f]{8}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{12}\-\d+\-[0-9A-Fa-f]{16}$/; // if the title is uuid from cp api then we need to generate title using tags
    if (!title || pattern.test(title)) { // if there is not title from cp api or title from cp api is uuid then generating title using description
        if (data.description && data.description.length < 50) {
            title = description; // if the description from cp api is less than 50 char then take entire description as title
        } else if (!data.description) {
            title = description.substring(56, 100); // to take substring after the fixed string of "This Photoshop express published asset represents" 
        } else title = description.substring(0, 56); // if the description from cp api is greater than 50 character
    }
    return {
        title: capitalizeFirstLetter(title),
        description: capitalizeFirstLetter(description)
    }
}

export const generateBranchDeepLink = uuid => {
    const url = `https://discover.photoshop.com/en/discover/category/all/${uuid}`;
    const body = {
        "branch_key": "key_live_fhPo4Nlbi7AIL5ltTMYpxllnxvljUYEs",
        "auto_fetch": true,
        "data": {
            "$canonical_url": url,
        },
        "type": 1
    }
    fetch('https://api.branch.io/v1/url', { method: "POST", body: JSON.stringify(body) }).then(res => res.json()).then(res => {
        window.location.href = res.url
    }).catch(() => {
        window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
    });
}

export const sendAdobeAnalyticsPageEvent = categoryName => {
    try {
        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.action.name', 'category_tapped');
        digitalData._set('digitalData.prop7', categoryName);

        _satellite.track('state');
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsSearchEvent = searchQuery => {
    try {
        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Search');
        digitalData._set('digitalData.search.searchInfo.keyword', searchQuery);
        digitalData._set('digitalData.search.searchInfo.type', 'typed');

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsShareEvent = itemName => {
    try {
        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Share');
        digitalData._set('digitalData.primaryEvent.eventInfo.eventAction', itemName);

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsAssetClickedEvent = assetId => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Asset_Opened');
        digitalData._set('digitalData.primaryEvent.eventInfo.eventAction', assetId);

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsLoadMoreEvent = () => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Load More');

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsTryNowEvent = (initiatingSource) => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'TryNow_tapped');
        digitalData._set('digitalData.primaryEvent.eventInfo.eventAction', initiatingSource);

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsCategoryClickEvent = (category) => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Category_Tapped');
        digitalData._set('digitalData.primaryEvent.eventInfo.eventAction', category);

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsModalCloseEvent = () => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Cross_Button_tapped');

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsUserProfile = () => {
    try {

        /* eslint no-underscore-dangle: 0 */
        digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'User_Profile_Opened');

        _satellite.track('event', {
            digitalData: digitalData._snapshot()
        });
    } catch (e) { console.log('Error in sending analytics event') }
}

export const sendAdobeAnalyticsReferrer = () => {
    try {
        // Extract the hostname
        let fullURL = document.location.href;
        let hostname = new URL(fullURL).hostname;

        // Extract the referrer
        let referrer=document.referrer

        // Only send analytics data when referrer is not same as hostname.
        if(!referrer.includes(hostname)){
            /* eslint no-underscore-dangle: 0 */

            digitalData._set('digitalData.primaryEvent.eventInfo.eventName', 'Referrer');
            digitalData._set('digitalData.primaryEvent.eventInfo.eventAction', referrer);

            _satellite.track('event', {
                digitalData: digitalData._snapshot()
            });
        }

    } catch (e) { console.log('Error in sending analytics event')
 }
}

export const capitalizeFirstLetter = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);
