import { Flex } from "@adobe/react-spectrum";
import PlayButton from '@/assets/Frame.svg';
import WorkFlowItem from "./WorkflowItem";
import Image from "next/image";

import style from './featureItem.module.scss';
import { useRef, useState } from "react";

export default function FeatureItem({ icon, heading, subHeading, text, linkText, file }) {

    const [active, setActive] = useState<boolean>(false);

    const videoRef = useRef<any>();

    const handleClick = () => {
        setActive(true);
        videoRef.current.play();
    }


    return <Flex direction={'column'} gap={10} justifyContent={'space-between'}>
        <WorkFlowItem icon={icon} heading={heading} subHeading={subHeading} text={text} linkText={linkText} />
        <div className={style.mediaContainer}>
            <video src={`https://${process.env.NEXT_PUBLIC_HOME_SCREEN_URL}/${file}.mp4`} width={400} height={205} style={{ width: "100%", height: "auto" }} autoPlay ref={videoRef} onEnded={() => setActive(false)} />
            <div className={style.playBtnContainer}>
                <Image hidden={active} src={PlayButton} width={70} height={70} alt="alt text" className={style.playBtn} onClick={handleClick} />
            </div>
        </div>
    </Flex>
}