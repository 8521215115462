"use client";
import { Button, Flex, Text, View } from "@adobe/react-spectrum";
import style from './frame.module.scss'
import HealIcon from '@spectrum-icons/workflow/Heal';
import VolumeIcon from '@spectrum-icons/workflow/VolumeThree';
import VolumeMute from '@spectrum-icons/workflow/VolumeMute';
import { useEffect, useState } from "react";
import FrameTabs from "./Tabs";
import { Link } from 'react-scroll';
import { useTranslations } from "next-intl";
import { sendAdobeAnalyticsReferrer } from '@/utils/utils';

export default function TopFrame() {
    const [workflow, setWorkflow] = useState(true);
    const [feature, setFeature] = useState(false);
    const [mute, setMute] = useState(true);
    const t = useTranslations();

    useEffect(()=>{
        sendAdobeAnalyticsReferrer();
    },[])

    return <>
        <Flex justifyContent={'center'} direction={'column'} alignItems={'center'} UNSAFE_className={style.mainFrame} position={'sticky'} top={'-370px'}>
            <View paddingTop={{ base: 15, M: 50 }} paddingBottom={50} >
                <Flex justifyContent={'center'}  >
                    <Flex justifyContent={'center'} position={'relative'} >
                        <video autoPlay src={`https://${process.env.NEXT_PUBLIC_HOME_SCREEN_URL}/basic_heal_merged.mp4`} loop width={644} height={359} className={style.framImg} muted={mute} />
                        <Flex position={'absolute'} bottom={15} justifyContent={'space-between'} left={0} right={0} width={'94%'} marginX={'auto'}>
                            <div />
                            <View>
                                <HealIcon size="S" UNSAFE_style={{ color: "white" }} />
                                <Text UNSAFE_style={{ color: "white", }} marginStart={4}>Basic Heal</Text>
                            </View>
                            <Button variant="overBackground" marginEnd={20} UNSAFE_style={{ border: "none" }} onPress={() => setMute(!mute)}>
                                {!mute ? <VolumeMute size="S" UNSAFE_style={{ color: "white" }} /> : <VolumeIcon size="S" UNSAFE_style={{ color: "white" }} />}
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </View>
        </Flex>
        <Flex position={'sticky'} top={65} zIndex={10} left={0} right={0} justifyContent={'center'} alignItems={'end'} UNSAFE_className={style.tabs}>
            <Flex gap={10} marginBottom={7}>
                <Link
                    activeClass="active"
                    to="workflow"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={(() => {
                        setWorkflow(true);
                        setFeature(false);
                    })}
                >
                    <span> <Text UNSAFE_className={`${style.tabText} ${workflow && style.active}`}>{t('homeScreen.workflow')}</Text></span>
                </Link>
                <Link
                    activeClass="active"
                    to="features"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    onClick={() => {
                        setWorkflow(false);
                        setFeature(true);
                    }}
                >
                    <Text UNSAFE_className={`${style.tabText} ${feature && style.active}`}>{ t('homeScreen.feature') }</Text>
                </Link>
            </Flex>
        </Flex>
        <FrameTabs />
    </>
}