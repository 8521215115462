/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

'use client';
import { Button, Content, Dialog, DialogContainer, Flex, Grid, Heading, View, useDialogContainer, Text } from '@adobe/react-spectrum';
import Image from 'next/image'
import PSXQR from '@/assets/PSX-QR.svg'
import PSXLogo from '@/assets/psx.png'
import Close from '@spectrum-icons/workflow/Close';
import CropLightning from '@spectrum-icons/workflow/CropLightning';
import Gallery from '@spectrum-icons/workflow/CoverImage';
import GalleryAdd from '@spectrum-icons/workflow/ImageAlbum';
import { useEffect } from 'react';
import style from './modal.module.scss';
import { sendAdobeAnalyticsModalCloseEvent } from '@/utils/utils';
import { useTranslations } from 'next-intl';


export default function QRModal({ open = false, setOpen, lang = "en" }) {
    return <DialogContainer type="modal" onDismiss={() => setOpen(false)}>
        {open && <QRDialog lang={lang} />}
    </DialogContainer>
}


function QRDialog({ lang }) {
    const dialog = useDialogContainer();
    const t = useTranslations("");

    useEffect(() => {
    }, [lang]);

    const handleClose = () => {
        dialog.dismiss();
        sendAdobeAnalyticsModalCloseEvent();
    }

    return <Dialog width={"70vw"} height={"70vh"}>
        <Content height={"100%"}>
            <Grid
                columns={["1fr", "1fr"]}
                height="100%"
                gap="size-100"

            >
                <Flex direction={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Image src={PSXQR} width={40} height={40} alt="PSX QR Code" className={style.qrCode} />
                </Flex>
                <View UNSAFE_className={style.backgroundColor} overflow={"auto"} height={"100%"}>
                    <View marginTop={10} height={"100px"}>
                        <Flex justifyContent={'center'} height={'auto'} direction={'column'} maxWidth={"90%"} marginX={"auto"} rowGap={15}>
                            <Image src={PSXLogo} width={40} height={40} alt="PSX Logo" />
                            <Heading level={2}>
                                {t('banner_heading')} Photoshop Express Mobile App
                            </Heading>
                            <Text>
                                {t('banner_line1')}
                            </Text>
                            <Flex direction={'column'} gap={8}>
                            <Flex gap={10}>
                                <CropLightning /> <Text UNSAFE_className={style.textFont}>{t('banner_line2')}</Text>
                            </Flex>
                            <Flex gap={10}>
                                <Gallery /> <Text UNSAFE_className={style.textFont}>{t('banner_line3')}</Text>
                            </Flex>
                            <Flex gap={10}>
                                <GalleryAdd /> <Text UNSAFE_className={style.textFont}>{t('banner_line4')}</Text>
                            </Flex>
                        </Flex>
                        <Text UNSAFE_className={style.bannerLine5}>
                            {t('banner_line5')}
                        </Text>
                    </Flex>
                    </View>
                </View>
            </Grid>
        </Content>
        <Button variant="overBackground" onPress={handleClose} position="absolute" top={-15} right={-15} UNSAFE_className={style.cancelButton} >
            <Close />
        </Button>
    </Dialog>
}