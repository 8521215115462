import { Flex, Heading, Text } from "@adobe/react-spectrum";
import Link from "next/link";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import QRModal from "../QRModal/Modal";
import Image from "next/image";

export default function WorkFlowItem({ heading, subHeading, text, linkText, icon }) {
    const [modal, setModal] = useState(false);
    const [icons, setIcons] = useState("");
    const path = usePathname();
    const routes: Array<string> = path.split("/");

    useEffect(() => {
        (async function () {
            let importedIcon = await import(`@/assets/Feature icons/${icon}`);
            setIcons(importedIcon.default);
        })()
    }, []);

    const handleModal = () => {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        } else
            setModal(!modal);
    }



    return <Flex direction={'column'} gap={10}>
        <Flex gap={10}>
            <Image src={icons} width={50} height={50} alt="" />
            <Flex direction={'column'} justifyContent={'center'}>
                <Heading level={4} UNSAFE_style={{ textTransform: "capitalize" }}>{heading}</Heading>
                <Text>{subHeading}</Text>
            </Flex>
        </Flex>
        <Text>
            {`${text} `}
            <Link href={"#"} style={{ textDecoration: "underline", color: "#0265DC", textTransform: 'capitalize' }} onClick={handleModal}>
                {linkText}
            </Link>
        </Text>
        <QRModal open={modal} setOpen={handleModal} lang={routes[1]} />
    </Flex>
}