import { Button, Divider, Flex, Grid, Heading, Text, View, repeat } from "@adobe/react-spectrum";
import WorkFlowItem from "./WorkflowItem";
import FeatureItem from "./FeatureItem";
import { featureItems, workflowsItems } from "@/constants/constants";
import { useTranslations } from "next-intl";
import Download from '@spectrum-icons/workflow/Download';
import { usePathname } from "next/navigation";
import { useState } from "react";
import QRModal from "../QRModal/Modal";


export default function Workflows() {
    const t = useTranslations('');
    const [modal, setModal] = useState(false);
    const path = usePathname();
    const routes: Array<string> = path.split("/");

    const handleModal = () => {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        } else
            setModal(!modal);
    }

    return <View width={'95%'} marginX={'auto'}>
        <Flex marginTop={20} direction={'column'} gap={20}>
            <Flex justifyContent={{ S: 'start', L: 'center' }}>
                <Heading>{t('homeScreen.workflows_heading')}</Heading>
            </Flex>
            <Grid columns={{ S: ['1fr'], M: repeat(3, '1fr') }} rowGap={'32px'} columnGap={52}>
                {workflowsItems.map((item, key) => {
                    return <WorkFlowItem icon={item.icon} key={key} heading={t(`homeScreen.workflows.${item.name}.title`)} subHeading={t(`homeScreen.workflows.${item.name}.heading`)} linkText={`${t('homeScreen.try_photoshop_express_today_link')}`} text={t(`homeScreen.workflows.${item.name}.subHeading`)} />

                })}
            </Grid>
            <Flex justifyContent={'center'}>
                <Button variant="accent" width={'size-1650'} isHidden={{ M: true }} onPress={handleModal}>
                    <Download />
                    <Text >{t('homeScreen.download_photoshop_express')}</Text>
                </Button>
            </Flex>
            <div id="features" />
            <Divider size="S" />
            <Flex justifyContent={{ S: 'start', L: 'center' }} flexGrow={1} isHidden={{ M: true }}>
                <Heading>{t('homeScreen.features_heading')}</Heading>
            </Flex>
            <Grid columns={{ S: ['1fr'], M: repeat(3, '1fr') }} rowGap={'32px'} columnGap={52}>
                {
                    featureItems.map((key, idx) => {
                        return <FeatureItem icon={key.icon} key={idx} file={key.name} heading={t(`homeScreen.features.${key.name.toLocaleLowerCase()}.title`)} subHeading={t(`homeScreen.features.${key.name.toLocaleLowerCase()}.heading`)} linkText={`${t('homeScreen.try_photoshop_express_today_link')}`} text={t(`homeScreen.features.${key.name.toLocaleLowerCase()}.subHeading`)} />
                    })
                }
            </Grid>
        </Flex>
        <QRModal open={modal} setOpen={handleModal} lang={routes[1]} />
    </View >
}